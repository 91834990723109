const colors = {
  background: "#fff",
  white: "#fff",
  blue: "#00346B",
  blueNavy: "#0e0b5b",
  blueNavyLight: "#243e76",
  grayBlue: "#436790",
  darkSecondary: "#DB4653",
  secondary: "#E86D30",
  gray: "#7a7a7a",
  lightBlack: "#333333",
  grayLight: "#f7f7f7",
  backgroundLightGray: "#F2F2F2",
  grayBackgroundTextTitle: "#333333",
  grayLight2: "#d1d1d1",
  gray2: "#E8E8E8",
  gray4: "#BDBDBD",
  gray5: "#4f4f4f",
  mediumGray: "#b5b5b5",
  muted: "#f6f6f6",
  primary: "#00346B",
  landingPageOverlayColor: "#00346BE0",
  text: "#555",
  heading: "#0e0b5b",
  blueSelectSquad: "#8399B3",
  blueMedium: "#002955",
  blueSelect: "#0C62A4",
  blueMediumDark: "#00264F",
  blueDark: "#002043",
  alternateSectionBackground: "#F0F3F6",
}

const defaultButton = {
  "&.invisible": {
    visibility: "hidden",
  },
  "&.zoom": {
    transition: "transform .2s",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.075)",
    },
  },
  "&:hover": {
    opacity: 0.8,
  },

  "&.bold": {
    fontWeight: "bold",
  },
  borderRadius: 50,
  color: "white",
  fontFamily: "heading",
  fontSize: [1, 1, 2],
  fontWeight: 400,
  overflow: "hidden",
  padding: "14px 35px",
  textOverflow: "ellipsis",
  textAlign: "center",
  whiteSpace: "nowrap",
  width: ["100%", "fit-content", "fit-content"],
  "&.is-link": {
    background: "transparent",
  },
}

export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Lato, sans-serif",
    heading: "Nunito, sans-serif",
    monospace: "Menlo, monospace",
  },
  text: {
    caps: {
      background: `linear-gradient(112deg, ${colors.darkSecondary} 0%, ${colors.secondary} 100%)`,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textTransform: "uppercase",
      letterSpacing: 1,
      fontWeight: "bold",
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    thin: 200,
    light: 300,
    body: 400,
    heading: 700,
    bold: 700,
  },
  breakpoints: ["40em", "56em", "64em"],
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  shadows: {
    card: "0px 6px 8px #0D0D361A",
    cardHovered: "0px 6px 8px #0D0D364F",
    form: "0px 10px 12px rgba(0, 0, 0, 0.3)",
  },
  colors,

  variants: {
    linkButton: {
      ...defaultButton,
      background: `linear-gradient(90deg, ${colors.darkSecondary} 0%, ${colors.secondary} 100%)`,
    },
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card",
    },
  },
  radii: {
    box: 6,
  },
  buttons: {
    step: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      background: "transparent",
      borderWidth: "2px",
      fontFamily: "heading",
      fontWeight: "bold",
      borderStyle: "solid",
      borderRadius: "50px",
      cursor: "pointer",
      outline: "none",
      padding: 2,
      svg: {
        display: "block",
      },
    },
    link: {
      fontFamily: "body",
      fontWeight: "light",
      padding: 0,
      opacity: 0.5,
      background: "transparent",
      cursor: "pointer",
      "&.active": {
        fontWeight: "bold",
        opacity: 1,
      },
      "&:hover": {
        opacity: 0.9,
      },
    },
    popUp: {
      ...defaultButton,
      width: "100%",
      backgroundColor: "blueSelect",
      padding: 12,
      cursor: "pointer",
    },
    gradient: {
      ...defaultButton,
      background: `linear-gradient(90deg, ${colors.secondary} 0%, ${colors.darkSecondary} 100%)`,
      width: "100%",
      fontSize: 2,
      fontWeight: "bold",
    },
    outlined: {
      ...defaultButton,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "secondary",
      background: "transparent",
    },
    rounded: {
      ...defaultButton,
      background: `linear-gradient(90deg, ${colors.secondary} 0%, ${colors.darkSecondary} 100%)`,
      borderRadius: 9999,
      display: "inline",
      padding: 2,
      svg: {
        display: "block",
      },
    },
    primary: {
      ...defaultButton,
      background: `linear-gradient(90deg, ${colors.secondary} 0%, ${colors.darkSecondary} 100%)`,
    },
    blue: {
      ...defaultButton,
      background: "#0C62A4",
      width: "100%",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
  },

  forms: {
    baseColorInput: {
      backgroundColor: "blueDark",
      paddingY: 2,
      paddingX: 3,
      my: [1, 1, 0],
      borderColor: "blueSelect",
      borderWidth: 1,
      borderStyle: "solid",
      color: "white",
      borderRadius: "box",
      fontFamily: "heading",
      "&::placeholder": {
        opacity: 0.4,
      },
    },
    borderedInput: {
      backgroundColor: "white",
      paddingY: 2,
      paddingX: 3,
      borderColor: "grayLight2",
      borderWidth: 1,
      borderStyle: "solid",
      color: "text",
      borderRadius: "box",
      fontFamily: "heading",
      "&::placeholder": {
        opacity: 0.4,
      },
    },
    label: {
      fontSize: 1,
      fontWeight: "bold",
      color: "heading",
      flexDirection: "column",
    },
    select: {
      backgroundColor: "transparent",
      color: "white",
      fontFamily: "body",
      width: "100%",
      "&+svg": {
        fill: "white",
      },
    },

    input: {
      paddingY: 2,
      paddingX: 1,
      border: "none",
      borderColor: "primary",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      marginTop: 1,
      color: "text",
      "&::placeholder": {
        opacity: 0.4,
      },
    },
  },
}
