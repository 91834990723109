import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie'

export function getTrackingId() {
  const COOKIE_TRACK_KEY = "tracking_id"
  const cookieTrackingId = Cookies.get(COOKIE_TRACK_KEY)
  const trackingId = cookieTrackingId || uuidv4()
  if (!cookieTrackingId) {
    Cookies.set(COOKIE_TRACK_KEY, trackingId, {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path: "/",
      expires: 2147483647
    })
  }
  return trackingId
}